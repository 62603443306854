










































































import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import GoBackButton from "@/components/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from "@/components/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import { InputSetups } from "@/mixins/input-setups";
import NewUsersLimitHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/NewUsersLimitHandlerModule/NewUsersLimitHandlerModuleBuilder";
import Placeholders from "@/mixins/placeholders/placeholders";

import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      MediaEditorMode,
      StepUnit
    }
  },
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SaveModuleButton,
    GoBackButton,
    TimeUnitInput,
    SwitchInput,
    MultiMessageEditorWithMediaInput
  },
})
export default class NewUsersLimitHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders) {
  @VModel() module!: NewUsersLimitHandlerModuleBuilder
}
